import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    init: { 
      showChat: false,
      address: {},
      viewPrivKey: {
        state: 'init',

      },
      viewPrivKeyStep2: {
        state: 'state'
      },
      privClose: {
        state: ''
      },
      address: {},
      profile: {

      },
      fund: {
        balance: 0,
        history: [],
      },
      verifications: {
        balance: 0,
        history: [],
      },
      rejections: {
        balance: 0,
        history: [],
      },
      mintedFund: {
        balance: 0,
        history: [],
      },
      selectedAsset: {

      }
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
